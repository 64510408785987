import React, {useState} from 'react';
import Modal from '../Modal/Modal';
import RadioButton from '../RadioButton/RadioButton';
import reloadIcon from '../../images/reload-icon.png';

import Select from 'react-select'

import './CreateServiceCallPopup.scss';

import serviceCallImg1 from '../../images/ico-service-call-1.png';
import serviceCallImg2 from '../../images/ico-service-call-2.png';
import serviceCallImg3 from '../../images/ico-service-call-3.png';
import serviceCallImg4 from '../../images/ico-service-call-4.png';
import serviceCallImg5 from '../../images/ico-service-call-5.png';
import deleteImg from "../../images/ico-delete.png";

const CreateServiceCallPopup = ({
        isOpen,
        onClose,
        bgHeader,
    }) => {

	const optionsDepartment = [
		{ value: 'Surgery', label: 'Surgery' },
		{ value: 'Urgent', label: 'Urgent' }
	]

	const optionsRoom = [
		{ value: '1', label: '1' },
		{ value: '2', label: '2' },
		{ value: '3', label: '3' },
		{ value: '4', label: '4' },
		{ value: '5', label: '5' }
	]

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			title={"פתיחת קריאה חדשה"}
			modalHeaderBg={bgHeader}
			modalHeaderColor="#fff"
		>
			<div className="subtitle">
				<h3>פרטי הקריאה</h3>
			</div>
			<div className="popup-content">
				<div className={"create-service-call-form"}>
					<div className={'select-type'}>
						<label>סוג קריאה <span>(אפשר לבחור יותר מסוג קריאה אחד)</span></label>
						<ul className={'service-type'}>
							<li>
								<input type={'checkbox'} id={'warm-attitude'} />
								<label htmlFor={'warm-attitude'}>
									<img src={serviceCallImg2} alt={`Service Call icon`} width={"19"} height={"31"} />
									<span>יחס חם</span>
								</label>
							</li>
							<li>
								<input type={'checkbox'} id={'loneliness'} />
								<label htmlFor={'loneliness'}>
									<img src={serviceCallImg3} alt={`Service Call icon`} width={'28'} height={'25'}/>
									<span>הפגת בדידות</span>
								</label>
							</li>
							<li>
								<input type={'checkbox'} id={'gifts'} />
								<label htmlFor={'gifts'}>
									<img src={serviceCallImg4} alt={`Service Call icon`} width={'28'} height={'28'}/>
									<span>מתנות</span>
								</label>
							</li>
							<li>
								<input type={'checkbox'} id={'birthday'} />
								<label htmlFor={'birthday'}>
									<img src={serviceCallImg5} alt={`Service Call icon`} width={'29'} height={'31'}/>
									<span>יום הולדת</span>
								</label>
							</li>
						</ul>
					</div>
				</div>
				<div className={'new-call-details-form'}>
					<div className={'row half-width-fields'}>
						<input type={'text'} placeholder={'שם מזמין הביקור'}/>
						<input type={'text'} placeholder={'טל׳ מזמין הביקור'}/>
					</div>
					<div className={'row'}>
						<input type={'text'} placeholder={'שם המאושפז'}/>
					</div>
					<div className={'row patient-locality'}>
						<div className={'department'}>
							<Select
								options={optionsDepartment}
								placeholder={"מחלקה"}
							/>
						</div>
						<div className={'room'}>
							<Select
								options={optionsRoom}
								placeholder={"חדר"}
							/>
						</div>
					</div>
					<div className={'row'}>
						<textarea placeholder={'הערות ופרטים נוספים'}></textarea>
					</div>
					<div className={'row is-employee'}>
						<label>האם את/ה עובד/ת בית החולים?</label>
						<div className={'yes-no-selection'}>
							<input type={'radio'} id={'yes'} name={'is-employee'} checked={'checked'} />
							<label htmlFor={'yes'}>כן</label>
							<input type={'radio'} id={'no'} name={'is-employee'} />
							<label htmlFor={'no'}>לא</label>
						</div>
					</div>
					<button className="button green">
						פתח קריאה
					</button>
				</div>
			</div>
		</Modal>
	);
};

export default CreateServiceCallPopup;
