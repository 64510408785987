import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../hooks/AuthProvider';
import useStagesStore from '../../store/useStagesStore';
import Loader from '../../components/Loader/Loader';
import reloadIcon from '../../images/reload-icon.png';
import './SeniorVolunteerSelection.scss';
import {API_BASE} from "../../api/constants";

const SeniorVolunteerSelection = () => {
  const [volunteers, setVolunteers] = useState([]);
  const [selectedVolunteer, setSelectedVolunteer] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const auth = useAuth();
  const navigate = useNavigate();
  const stages = useStagesStore((state) => state.stages);
  const setStage = useStagesStore((state) => state.setStage);

  const fetchVolunteers = useCallback(async () => {
    setLoading(true);
    try {
      const hospitalId = stages['select-hospital'].id;
      console.log('select-hospital-id', hospitalId);
      const response = await axios.get(
          API_BASE+`/api/assistant-instructors?hospitalId=${hospitalId}`,
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );
      console.log('/api/assistant-instructors', response.data);
      setVolunteers(response.data);
      setLoading(false);
    } catch (error) {
      setError('Failed to fetch volunteers');
      setLoading(false);
    }
  }, [auth.token, stages]);

  useEffect(() => {
    fetchVolunteers();
  }, [fetchVolunteers]);

  const handleVolunteerSelection = (e) => {
    const selectedId = e.target.value;
    const volunteer = volunteers.find((vol) => vol.Id === selectedId);
    setSelectedVolunteer(volunteer);
  };

  const handleVolunteerSubmit = (e) => {
    e.preventDefault();
    if (selectedVolunteer) {
      const { Name, Id, Status } = selectedVolunteer;
      setStage('senior-volunteer-assistant', { Name, Id, Status });
    } else {
      setStage('senior-volunteer-assistant', null);
    }
    setStage('currentStage', 'select-volunteer-type');
    navigate('/select-volunteer-type');
  };

  const handleSingleVolunteer = () => {
    setStage('senior-volunteer-assistant', null);
    setStage('currentStage', 'select-volunteer-type');
    navigate('/select-volunteer-type');
  };

  return (
    <div className="loginForm-wrapper">
      <strong className="logo">
        <Link to={'/'}>
          <img
            src={'./images/logo-big.png'}
            alt={''}
            width={'254px'}
            height={'93px'}
          />
        </Link>
      </strong>
      <form onSubmit={handleVolunteerSubmit}>
        <div className={'selectVolounteer'}>
          <h2>
            אם יש איתך מתנדב/ת נוספ/ת
            <br />
            בחר מהרשימה
          </h2>
          <div className="topVolounteersList__wrap">
            {loading ? (
              <Loader loaderAbsolut={true} />
            ) : (
              <ul className={'topVolounteersList'}>
                {volunteers.map((volunteer) => (
                  <li key={volunteer.Id}>
                    <input
                      type={'radio'}
                      name={'topVolounteerName'}
                      id={volunteer.Id}
                      value={volunteer.Id}
                      onChange={handleVolunteerSelection}
                    />
                    <label htmlFor={volunteer.Id}>
                      <span>{volunteer.Name}</span>
                    </label>
                  </li>
                ))}
              </ul>
            )}
          </div>
          {!loading && (
            <button
              type="button"
              className={'reloadList'}
              onClick={fetchVolunteers}
            >
              <span className="icon">
                <img src={reloadIcon} alt="icon" />
              </span>
              <span className="text">רענן רשימה</span>
            </button>
          )}
          <div className={'selectVolunteerButton'}>
            <button type="submit">בחר</button>
          </div>
        </div>
        <button
          type="button"
          className="style-button-red"
          onClick={handleSingleVolunteer}
        >
          אני מתנדב יחיד
        </button>
        {error && <p>{error}</p>}
      </form>
    </div>
  );
};

export default SeniorVolunteerSelection;
