import React, {useState} from 'react';
import './ServiceCallItem.scss';

import ServiceCallInfoPopup from '../../components/ServiceCallInfoPopup/ServiceCallInfoPopup';

import deleteImg from '../../images/ico-delete.png';
import icoInfo from '../../images/ico-info.png';
import VolunteerSelectionModal from "../VolunteerSelectionModal/VolunteerSelectionModal";

const ServiceCallSelectionItem = ({
	                                  serviceTypeIcon,
	                                  serviceTypeIcon2,
	                                  serviceTypeName,
	                                  patientName,
	                                  departmentName,
	                                  room,
	                                  points,
	                                  date,
	                                  time,
	                                  isReserved,
	                                  onButtonClick,
                                  }) => {
	const [showServiceCallInfoPopup, setShowServiceCallInfoPopup] = useState(false)
	return (
		<div className={`service-call-selection-item${isReserved ? ' reserved' : ''}`}>
			{showServiceCallInfoPopup ? <div className={'popup-content'}>
				<ServiceCallInfoPopup
					isOpen={showServiceCallInfoPopup}
					onClose={() => setShowServiceCallInfoPopup(false)}
					bgHeader="#b83b9d"
					isReserved={isReserved}

				/>
			</div> : ''}
			<div className={'block-holder'}>
				<ul className={'service-type'}>
					<li>
						<img src={serviceTypeIcon} alt={`${serviceTypeName} icon`}/>
					</li>
					{serviceTypeIcon2 ?
						<li>
							<img src={serviceTypeIcon2} alt={`service icon`}/>
						</li> :
						''
					}
				</ul>
				{isReserved ? <span className={'reserved-note'}>קריאה משוייכת</span> : ''}
				<button className={'btn-info'} onClick={() => setShowServiceCallInfoPopup(true)}>
					<img src={icoInfo} alt={"Service Call Info"} />
				</button>
				<span className='points'>
					<span>{points}</span>
					<img src="images/ico-heart.png" alt="" width={'27'} height={'23'}/>
				</span>
				<h2 className="patient-name">{patientName}</h2>
				<h4 className='departmentName'>{departmentName}</h4>
				<span className='room'>{room}</span>
				<span className='date-time'>נפתח ב: {date} {time}</span>
				<button className="button" onClick={onButtonClick}>
						{isReserved ? 'בטל שיוך ' : 'אני אלך לביקור'}
					{isReserved ? <img src={deleteImg} alt="delete" width={'18'} height={'21'}/> : ''}
				</button>
			</div>
		</div>
	);
};

export default ServiceCallSelectionItem;
