import React, { useState, useEffect } from 'react';
import Header from '../../components/Header/Header';
import HeaderInfoRight from '../../components/HeaderInfoRight/HeaderInfoRight';
import BackgroundLayoutFirst from '../../layouts/BackgroundLayoutFirst/BackgroundLayoutFirst';
import LogoIcon from '../../images/logo.png';
import bgHeaderImg from '../../images/bg-header-gray.png';
import BackgroundLayoutFirstImgBg from '../../images/background-layout-first.png';
import avatar1 from '../../images/photo-2.png';
import avatar2 from '../../images/photo-1.png';
import HeaderInfoRightIcon1 from '../../images/ico-hospital-litle.png';
import MainHeadingTop from '../../components/MainHeadingTop/MainHeadingTop';
import CartSelectionItem from '../../components/CartSelectionItem/CartSelectionItem';
import VolunteerSelectionModal from '../../components/VolunteerSelectionModal/VolunteerSelectionModal';

import cartProduct1 from '../../images/cart-product-1.png';
import cartProduct2 from '../../images/cart-product-2.png';
import cartProduct3 from '../../images/cart-product-3.png';
import cartProduct4 from '../../images/cart-product-4.png';
import cartProduct5 from '../../images/cart-product-5.png';
import cartProduct6 from '../../images/cart-product-6.png';
import cartProduct7 from '../../images/cart-product-7.png';

import './RoomsPages.scss';
import useUserStore from '../../store/userStore';
import useStagesStore from '../../store/useStagesStore';
import Loader from '../../components/Loader/Loader';

const CartSelection = () => {
    const user = useUserStore((state) => state.user);
    const [data, setData] = useState(null);
    const stages = useStagesStore((state) => state.stages);
    const [loading, setLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [participants, setParticipants] = useState([]);
    const [contentModalItme, setContentModalItme] = useState('');

    useEffect(() => {
        setTimeout(() => {
            const items = [
                {
                    id: 1,
                    title: 'עגלה',
                    subtitle: 'בננה',
                    icon: cartProduct1,
                    reserved: false,
                },
                {
                    id: 2,
                    title: 'עגלה',
                    subtitle: 'אננס',
                    icon: cartProduct2,
                    reserved: true,
                },
                {
                    id: 3,
                    title: 'עגלה',
                    subtitle: 'תות',
                    icon: cartProduct3,
                    reserved: false,
                },
                {
                    id: 4,
                    title: 'עגלה',
                    subtitle: 'אפרסק',
                    icon: cartProduct4,
                    reserved: true,
                },
                {
                    id: 5,
                    title: 'עגלה',
                    subtitle: 'אבטיח',
                    icon: cartProduct5,
                    reserved: false,
                },
                {
                    id: 6,
                    title: 'עגלה',
                    subtitle: 'לימון',
                    icon: cartProduct6,
                    reserved: false,
                },
                {
                    id: 7,
                    title: 'עגלה',
                    subtitle: 'תפוז',
                    icon: cartProduct7,
                    reserved: false,
                },
            ];

            setData({
                avatar1: user?.avatarUrl,
                avatar2: avatar2,
                middleContent: {
                    onlyText: null,
                    onlyListItems: [
                        {
                            label: 'בית החולים ' + `"${stages['select-hospital'].name}"`,
                            icon: HeaderInfoRightIcon1,
                        },
                    ],
                },
                title: 'Выберите товары для корзины',
                subtitle: null,
                items: items,
            });
            setLoading(false);
        }, 1000);
    }, []);

    const handleButtonClick = (item, numb) => {
        if (item.reserved) {
            setParticipants([]);
        } else {
            setParticipants([
                { name: 'קרל היינץ רומיניגה' },
                { name: 'פרנץ בקנבאואר' },
                { name: 'גיאורגי חאג׳י' },
                { name: 'רמי אבן-לבן' },
                { name: 'פרנצ׳סקו טוטי' },
                { name: 'ג׳ון אוגו' },
                { name: 'רומן פיליפצ׳וק' },
                { name: 'רוסלן ניגמטולין' },
            ]);
        }
        const newItem = {
            ...item,
            number: numb,
        };
        setContentModalItme(newItem);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setParticipants([]);
    };

    return (
        <BackgroundLayoutFirst
            backgroundImage={BackgroundLayoutFirstImgBg}
            backgroundColor="rgba(60, 75, 96, 0.8)"
            bottomDecorativeImage={null}
            classNameUnic="cart-style"
        >
            <Header
                leftBlock={
                    data ? (
                        !contentModalItme ? (
                            <HeaderInfoRight
                                avatar1={data.avatar1}
                                avatar2={data.avatar2}
                                middleContent={data.middleContent}
                                buttonBackgroundColor="#7f8ea1"
                            />
                        ) : (
                            <HeaderInfoRight
                                avatar1={data.avatar1}
                                avatar2={data.avatar2}
                                middleContent={data.middleContent}
                                buttonBackgroundColor="#7f8ea1"
                            />
                        )
                    ) : (
                        <Loader />
                    )
                }
                centerLogo={<img src={LogoIcon} alt="Logo" />}
                backgroundImage={bgHeaderImg}
                navBackgroundColor="rgba(60, 75, 96, 0.8)"
            />
            {loading ? (
                <Loader />
            ) : data ? (
                <div className="cart-selection">
                    <MainHeadingTop title="בחירת עגלה" />
                    <div className="cart-selection__items">
                        {data.items.map((item, index) => (
                            <CartSelectionItem
                                key={index}
                                index={index}
                                title={item.title}
                                subtitle={item.subtitle}
                                icon={item.icon}
                                isReserved={item.reserved}
                                onButtonClick={() => handleButtonClick(item, index + 1)}
                            />
                        ))}
                    </div>
                </div>
            ) : (
                <div>error</div>
            )}
            <VolunteerSelectionModal
                isOpen={isModalOpen}
                onClose={closeModal}
                participants={participants}
                contentObj={contentModalItme}
                bgHeader="#4ec8ff"
            />
        </BackgroundLayoutFirst>
    );
};

export default CartSelection;
