import React from 'react';
import './HeaderInfoRight.scss';
import { useNavigate } from 'react-router-dom';
import editIcon from '../../images/ico-edit.png';
import useStagesStore from '../../store/useStagesStore';

const HeaderInfoRight = ({
  avatar1,
  avatar2,
  middleContent,
  onBackClick,
  buttonBackgroundColor,
}) => {
  const navigate = useNavigate();
  const setStage = useStagesStore((state) => state.setStage);

  const handleEditStages = () => {
    setStage('currentStage', 'edit-stages');
    navigate('/edit-stages');
    console.log('Navigating to edit-stages');
  };

  return (
    <div
      className={
        middleContent.onlyText !== null
          ? 'header-info-right'
          : 'header-info-right no-right-border'
      }
    >
      <div className="header-info-right__avatars">
        <span className="border-item left"></span>
        {avatar1 && (
          <span className="header-info-right__avatar left">
            <img src={avatar1} alt="Avatar 1" />
          </span>
        )}
        {avatar2 && (
          <span className="header-info-right__avatar right">
            <img src={avatar2} alt="Avatar 2" />
          </span>
        )}

        <span className="border-item right"></span>
      </div>
      <div className="header-info-right__middle-content">
        {middleContent.onlyText !== null ? (
          <p>{middleContent.onlyText}</p>
        ) : (
          middleContent.onlyText == null && (
            <div className="header-info-right__icons">
              <div className="header-info-right__border first"></div>

              {middleContent.onlyListItems.map((item, index) => (
                <div key={index} className="header-info-right__item-wrap">
                  <span className="header-info-right__icon">
                    <img src={item.icon} alt="icon" />
                  </span>
                  <span className="header-info-right__label">{item.label}</span>
                </div>
              ))}

              <div className="header-info-right__border last"></div>
            </div>
          )
        )}
      </div>
      <button
        className="header-info-right__back-button"
        onClick={handleEditStages}
        style={{ backgroundColor: buttonBackgroundColor }}
      >
        <span className="icon">
          <img src={editIcon} alt="edit stages" />
        </span>
      </button>
    </div>
  );
};

export default HeaderInfoRight;
