import React from 'react';
import './HeaderTrollesCompetition.scss';
import {Link, useNavigate} from 'react-router-dom';
import useStagesStore from '../../store/useStagesStore';

import trolleyImg1 from '../../images/cart-product-1.png';
import trolleyImg2 from '../../images/cart-product-2.png';
import trolleyImg3 from '../../images/cart-product-3.png';
import trolleyImg4 from '../../images/cart-product-4.png';
import trolleyImg5 from '../../images/cart-product-5.png';
import trolleyImg6 from '../../images/cart-product-6.png';
import trolleyImg7 from '../../images/cart-product-7.png';

import leaderIco from '../../images/ico-leader.png';

const HeaderTrolleysCompetion = () => {
	const navigate = useNavigate();
	const setStage = useStagesStore((state) => state.setStage);

	const handleEditStages = () => {
		setStage('currentStage', 'edit-stages');
		navigate('/edit-stages');
	};

	return (
		<div className={'trolleys-competion'}>
			<ul>
				<li className={'leader'}>
					<div className={'img-holder'}>
						<img src={trolleyImg1} alt={""} />
					</div>
					<span className={'trolley-points'}>15</span>
					<span className={'leader-mark'}>
						<img src={leaderIco} alt={""} width={'27'} height={"27"} />
						<span>#1</span>
					</span>
				</li>
				<li>
					<div className={'img-holder'}>
						<img src={trolleyImg2} alt={""} />
					</div>
					<span className={'trolley-points'}>4</span>
				</li>
				<li>
					<div className={'img-holder'}>
						<img src={trolleyImg3} alt={""} />
					</div>
					<span className={'trolley-points'}>6</span>
				</li>
				<li>
					<div className={'img-holder'}>
						<img src={trolleyImg4} alt={""} />
					</div>
					<span className={'trolley-points'}>10</span>
				</li>
				<li>
					<div className={'img-holder'}>
						<img src={trolleyImg5} alt={""} />
					</div>
					<span className={'trolley-points'}>12</span>
				</li>
				<li>
					<div className={'img-holder'}>
						<img src={trolleyImg6} alt={""} />
					</div>
					<span className={'trolley-points'}>12</span>
				</li>
				<li>
					<div className={'img-holder'}>
						<img src={trolleyImg7} alt={""} />
					</div>
					<span className={'trolley-points'}>12</span>
				</li>
			</ul>
		</div>
	);
};

export default HeaderTrolleysCompetion;
