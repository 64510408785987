import React from 'react';
import './HeaderInfoLeft.scss';
import {Link, useNavigate} from 'react-router-dom';
import challengesIcon from '../../images/ico-challendges.png';
import useStagesStore from '../../store/useStagesStore';

const HeaderInfoLeft = ({
	                        middleContent,
	                        onBackClick,
	                        buttonBackgroundColor,
                        }) => {
	const navigate = useNavigate();
	const setStage = useStagesStore((state) => state.setStage);

	const handleEditStages = () => {
		setStage('currentStage', 'edit-stages');
		navigate('/edit-stages');
		console.log('Navigating to edit-stages');
	};

	return (
		<div className={'header-info-left'}>
			<div className="header-info-left__top">
				<span className="border-item left"></span>
				<div className="timer">
					<span className="time">02:58</span>
				</div>
				<span className="border-item right"></span>
			</div>
			<div className="header-info-left__middle-content">
				<div className="stats-info">
					<div className="total-applications">
						<span>קריאות שפתחנו</span>
						<strong>2/3</strong>
					</div>
					<div className="not-proceed-applications">
						<span>קריאות פעילות</span>
						<strong>3</strong>
					</div>
					<div className="proceeded-applications">
						<span>קריאות שטופלו</span>
						<strong>1</strong>
					</div>
					<div className="hearts-number">
						<span><img src="images/ico-heart.png" alt="" width={'28'} height={'24'}/></span>
						<strong>6</strong>
					</div>
				</div>
			</div>
			<Link to={'/challenges'} className={'btn-challenges'}>
                <span className="icon">
                    <img src={challengesIcon} alt="" width={'19'} height={'19'}/>
                </span>
                אתגרים
			</Link>
		</div>
	);
};

export default HeaderInfoLeft;
