import React, { useState, useEffect } from 'react';
import Header from '../../components/Header/Header';
import HeaderInfoRight from '../../components/HeaderInfoRight/HeaderInfoRight';
import BackgroundLayoutFirst from '../../layouts/BackgroundLayoutFirst/BackgroundLayoutFirst';
import LogoIcon from '../../images/logo.png';
import bgHeaderImg from '../../images/bg-header.png';
import BackgroundLayoutFirstImgBg from '../../images/background-layout-blue.gif';
import avatar1 from '../../images/photo-2.png';
import avatar2 from '../../images/photo-1.png';
import HeaderInfoRightIcon1 from '../../images/ico-hospital-litle.png';
import MainHeadingTop from '../../components/MainHeadingTop/MainHeadingTop';
import ServiceCallSelectionItem from '../../components/ServiceCallItem/ServiceCallItem';
import VolunteerSelectionModal from '../../components/VolunteerSelectionModal/VolunteerSelectionModal';
import FixedFooter from '../../components/FixedFooter/FixedFooter';

import serviceCallImg1 from '../../images/ico-service-call-1.png';
import serviceCallImg2 from '../../images/ico-service-call-2.png';
import serviceCallImg3 from '../../images/ico-service-call-3.png';
import serviceCallImg4 from '../../images/ico-service-call-4.png';
import serviceCallImg5 from '../../images/ico-service-call-5.png';


import './AssignedServiceCalls.scss';
import useUserStore from '../../store/userStore';
import useStagesStore from '../../store/useStagesStore';
import Loader from '../../components/Loader/Loader';
import {Link} from "react-router-dom";
import HeaderInfoLeft from "../../components/HeaderInfoLeft/HeaderInfoLeft";

const ServiceCallSelection = () => {
    const user = useUserStore((state) => state.user);
    const [data, setData] = useState(null);
    const stages = useStagesStore((state) => state.stages);
    const [loading, setLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [participants, setParticipants] = useState([]);
    const [contentModalItme, setContentModalItme] = useState('');

    useEffect(() => {
        setTimeout(() => {
            const items = [
                {
                    id: 1,
                    patientName: 'דב כלפון',
                    serviceTypeIcon: serviceCallImg3,
                    serviceTypeIcon2: serviceCallImg2,
                    departmentName: 'מחלקה כירורגית ב׳ ',
                    room: 'חדר 7',
                    points: '6',
                    date: '10.09.2024',
                    time: '09:42',
                    reserved: false,
                },
                {
                    id: 2,
                    patientName: 'גז׳גוז׳ ויז׳ינסקי',
                    serviceTypeIcon: serviceCallImg4,
                    departmentName: 'מחלקה כירורגית ב׳ ',
                    room: 'חדר 7',
                    points: '6',
                    date: '10.09.2024',
                    time: '09:42',
                    reserved: true,
                },
                {
                    id: 3,
                    patientName: 'איתן טיבי',
                    serviceTypeIcon: serviceCallImg5,
                    serviceTypeIcon2: serviceCallImg2,
                    departmentName: 'מחלקה כירורגית ב׳ ',
                    room: 'חדר 7',
                    points: '6',
                    date: '10.09.2024',
                    time: '09:42',
                    reserved: false,
                },
                {
                    id: 4,
                    patientName: 'קובי רפואה',
                    serviceTypeIcon: serviceCallImg3,
                    serviceTypeIcon2: serviceCallImg2,
                    departmentName: 'מחלקה כירורגית ב׳ ',
                    room: 'חדר 7',
                    points: '6',
                    date: '10.09.2024',
                    time: '09:42',
                    reserved: true,
                },
                {
                    id: 5,
                    patientName: 'לוקה טוני',
                    serviceTypeIcon: serviceCallImg5,
                    departmentName: 'מחלקה כירורגית ב׳ ',
                    room: 'חדר 7',
                    points: '6',
                    date: '10.09.2024',
                    time: '09:42',
                    reserved: false,
                },
                {
                    id: 6,
                    patientName: 'יצחק בן יצחק',
                    serviceTypeIcon: serviceCallImg4,
                    serviceTypeIcon2: serviceCallImg2,
                    departmentName: 'מחלקה כירורגית ב׳ ',
                    room: 'חדר 7',
                    points: '6',
                    date: '10.09.2024',
                    time: '09:42',
                    reserved: false,
                },
                {
                    id: 7,
                    patientName: 'דב כלפון',
                    serviceTypeIcon: serviceCallImg3,
                    serviceTypeIcon2: serviceCallImg2,
                    departmentName: 'מחלקה כירורגית ב׳ ',
                    room: 'חדר 7',
                    points: '6',
                    date: '10.09.2024',
                    time: '09:42',
                    reserved: false,
                },
                {
                    id: 8,
                    patientName: 'גז׳גוז׳ ויז׳ינסקי',
                    serviceTypeIcon: serviceCallImg4,
                    departmentName: 'מחלקה כירורגית ב׳ ',
                    room: 'חדר 7',
                    points: '6',
                    date: '10.09.2024',
                    time: '09:42',
                    reserved: false,
                },
                {
                    id: 9,
                    patientName: 'איתן טיבי',
                    serviceTypeIcon: serviceCallImg5,
                    serviceTypeIcon2: serviceCallImg2,
                    departmentName: 'מחלקה כירורגית ב׳ ',
                    room: 'חדר 7',
                    points: '6',
                    date: '10.09.2024',
                    time: '09:42',
                    reserved: false,
                },
            ];

            setData({
                avatar1: user?.avatarUrl,
                avatar2: avatar2,
                middleContent: {
                    onlyText: null,
                    onlyListItems: [
                        {
                            label: 'בית החולים ' + `"${stages['select-hospital'].name}"`,
                            icon: HeaderInfoRightIcon1,
                        },
                    ],
                },
                title: 'Choose products for trolley',
                subtitle: null,
                items: items,
            });
            setLoading(false);
        }, 1000);
    }, []);

    return (
        <BackgroundLayoutFirst
            backgroundImage={BackgroundLayoutFirstImgBg}
            backgroundColor="rgba(60, 75, 96, 0.8)"
            bottomDecorativeImage={null}
            classNameUnic="cart-style"
        >
            <Header
                additionalClass = {'activated'}
                rightBlock={
                    data ? (
                        !contentModalItme ? (
                            <HeaderInfoRight
                                avatar1={data.avatar1}
                                avatar2={data.avatar2}
                                middleContent={data.middleContent}
                                buttonBackgroundColor="#7f8ea1"
                            />
                        ) : (
                            <HeaderInfoRight
                                avatar1={data.avatar1}
                                avatar2={data.avatar2}
                                middleContent={data.middleContent}
                                buttonBackgroundColor="#7f8ea1"
                            />
                        )
                    ) : (
                        <Loader />
                    )
                }
                leftBlock={
                    <HeaderInfoLeft />
                }
                centerLogo={<img src={LogoIcon} alt="Logo" />}
                backgroundImage={bgHeaderImg}
                navBackgroundColor = {'#000322'}
            />
            {loading ? (
                <Loader />
            ) : data ? (
                <div className="service-call-selection">
                    <MainHeadingTop title="קריאות שירות" />
                    <nav className='service-call-filter'>
                        <ul>
                            <li className={'active'}>
                                <Link to={'/service-calls'}>
                                    <div className={'img-holder'}>
                                        <img src={serviceCallImg1} alt={`הכל icon`} width={'37'} height={'43'}/>
                                    </div>
                                    <strong>הכל</strong>
                                    <span className='quantity'>38 קריאות</span>
                                </Link>
                            </li>
                            <li>
                                <Link to={'/service-calls#warm-attitude'}>
                                    <div className={'img-holder'}>
                                        <img src={serviceCallImg2} alt={`יחס חם icon`} width={'19'} height={'31'}/>
                                    </div>
                                    <strong>יחס חם</strong>
                                    <span className='quantity'>10 קריאות</span>
                                </Link>
                            </li>
                            <li>
                                <Link to={'/service-calls#loneliness'}>
                                    <div className={'img-holder'}>
                                        <img src={serviceCallImg3} alt={`הפגת בדידות icon`} width={'28'} height={'25'}/>
                                    </div>
                                    <strong>הפגת בדידות</strong>
                                    <span className='quantity'>5 קריאות</span>
                                </Link>
                            </li>
                            <li>
                                <Link to={'/service-calls#gifts'}>
                                    <div className={'img-holder'}>
                                        <img src={serviceCallImg4} alt={`מתנות icon`} width={'28'} height={'28'}/>
                                    </div>
                                    <strong>מתנות</strong>
                                    <span className='quantity'>20 קריאות</span>
                                </Link>
                            </li>
                            <li>
                                <Link to={'/service-calls#birthday'}>
                                    <div className={'img-holder'}>
                                        <img src={serviceCallImg5} alt={`יום הולדת icon`} width={'29'} height={'31'}/>
                                    </div>
                                    <strong>יום הולדת</strong>
                                    <span className='quantity'>3 קריאות</span>
                                </Link>
                            </li>
                        </ul>
                    </nav>
                    <div className="service-call-selection__items">
                        {data.items.map((item, index) => (
                            <ServiceCallSelectionItem
                                key={index}
                                index={index}
                                patientName={item.patientName}
                                serviceTypeIcon={item.serviceTypeIcon}
                                serviceTypeIcon2={item.serviceTypeIcon2}
                                departmentName={item.departmentName}
                                room={item.room}
                                points={item.points}
                                date={item.date}
                                time={item.time}
                                isReserved={item.reserved}
                                onButtonClick={() => {}}
                            />
                        ))}
                    </div>
                </div>
            ) : (
                <div>error</div>
            )}
            <FixedFooter
                footerCenter = {
                    <div className={'footer-center-content'}>
                        <Link to={'#'} className={'btn-footer-center green'}>יצאנו לקריאות</Link>
                        <span className='selected-counter'>4/38</span>
                    </div>
                }
            />
        </BackgroundLayoutFirst>
    );
};

export default ServiceCallSelection;
