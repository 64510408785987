import React, {useState} from 'react';
import Modal from '../Modal/Modal';
import RadioButton from '../RadioButton/RadioButton';
import reloadIcon from '../../images/reload-icon.png';

import './ServiceCallInfoPopup.scss';

import serviceCallImg1 from '../../images/ico-service-call-1.png';
import serviceCallImg2 from '../../images/ico-service-call-2.png';
import serviceCallImg3 from '../../images/ico-service-call-3.png';
import serviceCallImg4 from '../../images/ico-service-call-4.png';
import serviceCallImg5 from '../../images/ico-service-call-5.png';
import deleteImg from "../../images/ico-delete.png";

const ServiceCallInfoPopup = ({
        isOpen,
        onClose,
        bgHeader,
        isReserved,
    }) => {

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			title={"פרטי קריאה מס׳ 133434"}
			modalHeaderBg={bgHeader}
			modalHeaderColor="#fff"
		>
			<div className="subtitle">
				<h3>פרטי הקריאה</h3>
			</div>
			<div className="subsubtitle">
				<h4>סוג קריאה</h4>
			</div>
			<div className="popup-content">
				<ul className={'service-type'}>
					<li className={'active'}>
						<img src={serviceCallImg2} alt={`Service Call icon`} width={"19"} height={"31"} />
						<span>יחס חם</span>
					</li>
					<li className={'active'}>
						<img src={serviceCallImg3} alt={`Service Call icon`} width={'28'} height={'25'}/>
						<span>הפגת בדידות</span>
					</li>
					<li>
						<img src={serviceCallImg4} alt={`Service Call icon`} width={'28'} height={'28'}/>
						<span>מתנות</span>
					</li>
					<li>
						<img src={serviceCallImg5} alt={`Service Call icon`} width={'29'} height={'31'}/>
						<span>יום הולדת</span>
					</li>
				</ul>
				<ul className={'service-call-details'}>
					<li>
						<strong className={'label'}>פותח/ת הקריאה:</strong>
						<span className={'value'}>ינאי כלפון</span>
					</li>
					<li>
						<strong className={'label'}>שם המאושפז/ת:</strong>
						<span className={'value'}>דב כלפון</span>
					</li>
					<li>
						<strong className={'label'}>מחלקה:</strong>
						<span className={'value'}>כירורגית ב׳ </span>
					</li>
					<li>
						<strong className={'label'}>מס׳ חדר:</strong>
						<span className={'value'}></span>
					</li>
					<li>
						<strong className={'label'}>טל׳ נייד של המטופל:</strong>
						<span className={'value'}>0541111478</span>
					</li>
					<li>
						<strong className={'label'}>הוראות הגעה למחלקה:</strong>
						<span className={'value'}>בניין אישפוז ב׳ (ליד מיון), מעליות מזרחיות, קומה 8, פונים ימינה.</span>
					</li>
					<li>
						<strong className={'label'}>איש קשר במחלקה:</strong>
						<span className={'value'}>בלה, אחות אחראית<br/> 058996655</span>
					</li>
					<li>
						<strong className={'label'}>מתנה:</strong>
						<span className={'value'}>מה כותבים כאן?</span>
					</li>
					<li>
						<strong className={'label'}>מועד פתיחת הקריאה:</strong>
						<span className={'value'}>09:42 ע״י מנהל</span>
					</li>
				</ul>
				<button className="button green" onClick={onClose} >
					{isReserved ? 'סגור קריאה ' : 'אני אלך לביקור'}
					{isReserved ? <img src={deleteImg} alt="delete" width={'18'} height={'21'} /> : ''}
				</button>
			</div>
		</Modal>
	);
};

export default ServiceCallInfoPopup;
