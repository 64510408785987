import React from 'react';
import useStagesStore from '../../store/useStagesStore';
import useUserStore from '../../store/userStore';
import { useAuth } from '../../hooks/AuthProvider';
import { useNavigate } from 'react-router-dom';
import avatar2 from '../../images/photo-1.png';
import editIcon from '../../images/ico-edit.png';
import logOutIcon from '../../images/log-out-icon.png';
import './StageEditingScreen.scss';

const StageEditingScreenLogin = () => {
  const { stages, setStage } = useStagesStore();
  const { user } = useUserStore();
  const { logOut } = useAuth();
  const navigate = useNavigate();

  const handleEdit = (e) => {
    e.preventDefault();
    setStage('select-senior-volunteer', {});
    setStage('currentStage', 'select-senior-volunteer');
    setTimeout(() => navigate('/select-senior-volunteer'), 0);
  };

  const handlelogOut = (e) => {
    e.preventDefault();
    logOut();
  };

  return (
    <div className="stage-editing-screen__stage login-stage-item">
      <div className="editing-screen-stage__top">
        <h2>{'מובילי צוות מתנדבים'}</h2>
        <h2>{'מוביל שותף'}</h2>
      </div>

      <div className="editing-screen-stage__midl">
        <div className="editing-screen-stage__item user-item">
          <div className="col">
            <div className="editing-screen-stage__item-avatar">
              <img src={user.avatarUrl} alt="avatar" />
            </div>
            <div className="editing-screen-stage__item-name">
              <h4>יובל שמואל</h4>
            </div>
          </div>
          <a
            href="#"
            className="editing-screen-stage__item-btn logs-out-btn"
            onClick={handlelogOut}
          >
            <span className="icon">
              <img src={logOutIcon} alt="icon" />
            </span>
            <span className="item-btn-text">התנתקות</span>
          </a>
        </div>

        <div className="line-item"></div>

        {stages['senior-volunteer-assistant'] && (
          <div className="editing-screen-stage__item assistant-item">
            <div className="col">
              <div className="editing-screen-stage__item-avatar">
                <img src={avatar2} alt="avatar" />
              </div>
              <div className="editing-screen-stage__item-name">
                <h4>חני נחמיאס</h4>
              </div>
            </div>
            <a
              href="#"
              className="editing-screen-stage__item-btn edit-btn"
              onClick={handleEdit}
            >
              <span className="icon">
                <img src={editIcon} alt="icon" />
              </span>
              <span className="item-btn-text">עריכה</span>
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default StageEditingScreenLogin;
