import React from 'react';
import './BackgroundLayoutFirst.scss';

const BackgroundLayoutFirst = ({
  backgroundImage,
  backgroundColor,
  bottomDecorativeImage,
  classNameUnic,
  children,
}) => {
  const className = backgroundImage
    ? 'background-layout-first--image'
    : 'background-layout-first--color';

  return (
    <div
      className={`background-layout-first ${className} ${classNameUnic}`}
      style={{ backgroundImage: `url(${backgroundImage})`, backgroundColor }}
    >
      {children}
      {bottomDecorativeImage && (
        <div
          className="background-layout-first__decorative"
          style={{ backgroundImage: `url(${bottomDecorativeImage})` }}
        ></div>
      )}
    </div>
  );
};

export default BackgroundLayoutFirst;
