import React from 'react';
import './Header.scss';
import {Link} from "react-router-dom";

const Header = ({
	                leftBlock,
	                centerLogo,
	                rightBlock,
	                backgroundImage,
	                navBackgroundColor,
	                additionalClass,
	                trolleysCompetion
                }) => {
	const headerStyle = {
		background: backgroundImage
			? `url(${backgroundImage}) no-repeat center center`
			: '#00008B',
		backgroundSize: 'cover',
	};

	const navStyle = {
		backgroundColor: navBackgroundColor || 'rgba(0, 0, 0, 0.5)',
	};

	let topButtons = [
		<Link to={'/instructions'} key="1">
			הדרכות
		</Link>,
		<Link to={'/instructions#help'} key="2">
			עזרה מרחוק
		</Link>,
	];

	const insertSeparators = (buttons) => {
		return buttons.map((button, index) => (
			<React.Fragment key={index}>
				{button}
				{index < buttons.length - 1 && <span className="separator">|</span>}
			</React.Fragment>
		));
	};

	return (
		<div className={"header " + (additionalClass ? additionalClass : '')} style={headerStyle}>
			<div className="container">
				<div className="header__top-buttons">
					<nav className="nav" style={navStyle}>
						{insertSeparators(topButtons)}
					</nav>
				</div>

				<div className="header__main-row">
					<div className="header__right-block">{rightBlock}</div>

					<div className="header__center-logo">
						{centerLogo}
						{trolleysCompetion ? trolleysCompetion : ''}
					</div>

					<div className="header__left-block">{leftBlock}</div>
				</div>
			</div>
		</div>
	);
};

export default Header;
