import React, { useState, useEffect } from 'react';
import Header from '../../components/Header/Header';
import HeaderInfoRight from '../../components/HeaderInfoRight/HeaderInfoRight';
import HeaderInfoLeft from "../../components/HeaderInfoLeft/HeaderInfoLeft";
import HeaderTrolleysCompetion from "../../components/HeaderTrollesCompetition/HeaderTrollesCompetition";
import BackgroundLayoutFirst from '../../layouts/BackgroundLayoutFirst/BackgroundLayoutFirst';
import LogoIcon from '../../images/logo.png';
import bgHeaderImg from '../../images/bg-header.png';
import BackgroundLayoutFirstImgBg from '../../images/background-layout-blue.gif';
import avatar1 from '../../images/photo-2.png';
import avatar2 from '../../images/photo-1.png';
import HeaderInfoRightIcon1 from '../../images/ico-hospital-litle.png';
import MainHeadingTop from '../../components/MainHeadingTop/MainHeadingTop';
import ServiceCallSelectionItem from '../../components/ServiceCallItem/ServiceCallItem';
import FixedFooter from '../../components/FixedFooter/FixedFooter';

import './RoomsPages.scss';
import useUserStore from '../../store/userStore';
import useStagesStore from '../../store/useStagesStore';
import Loader from '../../components/Loader/Loader';
import {Link} from "react-router-dom";
import Select from "react-select";

import roomIco from '../../images/ico-door.svg';
import icoInfo from '../../images/ico-info.png';

const TeamActivityRoomsList = () => {
    const user = useUserStore((state) => state.user);
    const [data, setData] = useState(null);
    const stages = useStagesStore((state) => state.stages);
    const [loading, setLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [participants, setParticipants] = useState([]);
    const [contentModalItme, setContentModalItme] = useState('');

    const optionsDepartment = [
        { value: 'Surgery', label: 'Surgery' },
        { value: 'Urgent', label: 'Urgent' }
    ]

    useEffect(() => {
        setTimeout(() => {

            setData({
                avatar1: user?.avatarUrl,
                avatar2: avatar2,
                middleContent: {
                    onlyText: null,
                    onlyListItems: [
                        {
                            label: 'בית החולים ' + `"${stages['select-hospital'].name}"`,
                            icon: HeaderInfoRightIcon1,
                        },
                    ],
                },
                title: 'Choose products for trolley',
                subtitle: null,
            });
            setLoading(false);
        }, 1000);
    }, []);

    return (
        <BackgroundLayoutFirst
            backgroundImage={BackgroundLayoutFirstImgBg}
            backgroundColor="rgba(60, 75, 96, 0.8)"
            bottomDecorativeImage={null}
            classNameUnic="cart-style"
        >
            <Header
                additionalClass = {'activated'}
                rightBlock={
                    data ? (
                        !contentModalItme ? (
                            <HeaderInfoRight
                                avatar1={data.avatar1}
                                avatar2={data.avatar2}
                                middleContent={data.middleContent}
                                buttonBackgroundColor="#7f8ea1"
                            />
                        ) : (
                            <HeaderInfoRight
                                avatar1={data.avatar1}
                                avatar2={data.avatar2}
                                middleContent={data.middleContent}
                                buttonBackgroundColor="#7f8ea1"
                            />
                        )
                    ) : (
                        <Loader />
                    )
                }
                leftBlock={
                    <HeaderInfoLeft />
                }
                trolleysCompetion={
                    <HeaderTrolleysCompetion />
                }
                centerLogo={<img src={LogoIcon} alt="Logo" />}
                backgroundImage={bgHeaderImg}
                navBackgroundColor = {'#000322'}
            />
            {loading ? (
                <Loader />
            ) : data ? (
                <div className="rooms-list">
                    <MainHeadingTop title="פעילות קבוצתית" />
                    <div className='select-department'>
                        <Select
                            options={optionsDepartment}
                            placeholder={"מחלקה"}
                        />
                        <span className={'info'}>
                            <img src={icoInfo} alt={"Hospital Departmen Info"} />
                        </span>
                    </div>
                    <div className={'rooms-holder'}>
                        <div className="room-block visited">
                            <span className={'status'}></span>
                            <strong>חדר 1</strong>
                            <img src={roomIco} alt={""} />
                        </div>
                        <div className="room-block visited">
                            <span className={'status'}></span>
                            <strong>חדר 2</strong>
                            <img src={roomIco} alt={""} />
                        </div>
                        <div className="room-block visited">
                            <span className={'status'}></span>
                            <strong>חדר 3</strong>
                            <img src={roomIco} alt={""} />
                        </div>
                        <div className="room-block visited">
                            <span className={'status'}></span>
                            <strong>חדר 4</strong>
                            <img src={roomIco} alt={""} />
                        </div>
                        <div className="room-block visited">
                            <span className={'status'}></span>
                            <strong>חדר 5</strong>
                            <img src={roomIco} alt={""} />
                        </div>
                        <div className="room-block visited">
                            <span className={'status'}></span>
                            <strong>חדר 6</strong>
                            <img src={roomIco} alt={""} />
                        </div>
                        <div className="room-block visited">
                            <span className={'status'}></span>
                            <strong>חדר 7</strong>
                            <img src={roomIco} alt={""} />
                        </div>
                        <div className="room-block visited">
                            <span className={'status'}></span>
                            <strong>חדר 8</strong>
                            <img src={roomIco} alt={""} />
                        </div>
                        <div className="room-block visited">
                            <span className={'status'}></span>
                            <strong>חדר 9</strong>
                            <img src={roomIco} alt={""} />
                        </div>
                        <div className="room-block visited">
                            <span className={'status'}></span>
                            <strong>חדר 10</strong>
                            <img src={roomIco} alt={""} />
                        </div>
                        <div className="room-block visited">
                            <span className={'status'}></span>
                            <strong>חדר 11</strong>
                            <img src={roomIco} alt={""} />
                        </div>
                        <div className="room-block visited">
                            <span className={'status'}></span>
                            <strong>חדר 12</strong>
                            <img src={roomIco} alt={""} />
                        </div>
                        <div className="room-block visited">
                            <span className={'status'}></span>
                            <strong>חדר 13</strong>
                            <img src={roomIco} alt={""} />
                        </div>
                        <div className="room-block visited">
                            <span className={'status'}></span>
                            <strong>חדר 14</strong>
                            <img src={roomIco} alt={""} />
                        </div>
                        <div className="room-block visited">
                            <span className={'status'}></span>
                            <strong>חדר 15</strong>
                            <img src={roomIco} alt={""} />
                        </div>
                        <div className="room-block visited">
                            <span className={'status'}></span>
                            <strong>חדר 4</strong>
                            <img src={roomIco} alt={""} />
                        </div>
                    </div>
                </div>
            ) : (
                <div>error</div>
            )}
            <FixedFooter
                footerCenter = {
                    <div className={'footer-center-content'}>
                        <Link to={'#'} className={'btn-footer-center green'}>יצאנו לקריאות</Link>
                        <span className='selected-counter'>4/38</span>
                    </div>
                }
            />
        </BackgroundLayoutFirst>
    );
};

export default TeamActivityRoomsList;
