import React from 'react';
import './MainHeadingTop.scss';

const MainHeadingTop = ({ title }) => {
  return (
    <div className="main-heading-top">
      <h1 className="main-heading-top__heading">{title}</h1>
    </div>
  );
};

export default MainHeadingTop;
