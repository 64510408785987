import React, { useEffect, useRef } from 'react';
import reloadIcon from '../../images/back-white-arrow-icon.png';
import './Modal.scss';

const Modal = ({
  isOpen,
  onClose,
  title,
  children,
  modalHeaderBg,
  modalHeaderColor,
}) => {
  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    // if (isOpen) {
    //   document.addEventListener('mousedown', handleClickOutside);
    // } else {
    //   document.removeEventListener('mousedown', handleClickOutside);
    // }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal" ref={modalRef}>
        <div
          className="modal-header"
          style={{ backgroundColor: modalHeaderBg, color: modalHeaderColor }}
        >
          <h2>{title}</h2>
          <button type="button" className="close-button" onClick={onClose}>
            <span className="icon">
              <img src={reloadIcon} alt="icon" />
            </span>
            <span className="text">אחורה</span>
          </button>
        </div>
        <div className="modal-content">{children}</div>
      </div>
    </div>
  );
};

export default Modal;
