import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const useStagesStore = create(
  persist(
    (set, get) => ({
      stages: {},
      currentStage: 'login',
      setStage: (stage, value) => {
        if (stage === 'currentStage') {
          set({ currentStage: value });
        } else {
          set((state) => ({
            stages: {
              ...state.stages,
              [stage]: value,
            },
          }));
        }
      },
      clearStages: () => {
        set({ stages: {}, currentStage: 'login' });
      },
    }),
    {
      name: 'stages-storage',
      getStorage: () => sessionStorage,
      serialize: (state) => JSON.stringify(state),
      deserialize: (str) => JSON.parse(str),
    }
  )
);

export default useStagesStore;
