import { useContext, createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useStagesStore from '../store/useStagesStore';
import useUserStore from '../store/userStore';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(sessionStorage.getItem('site') || '');
  const clearStages = useStagesStore((state) => state.clearStages);
  const clearUser = useUserStore((state) => state.clearUser);
  const navigate = useNavigate();

  const loginAction = (data) => {
    setUser(data.user);
    setToken(data.token);
    sessionStorage.setItem('site', data.token);
  };

  const logOut = () => {
    setUser(null);
    setToken('');
    sessionStorage.removeItem('site');
    clearStages();
    clearUser();
    navigate('/login');
  };

  return (
    <AuthContext.Provider value={{ token, user, loginAction, logOut }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};
