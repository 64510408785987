import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from '../../components/Header/Header';
import HeaderInfoRight from '../../components/HeaderInfoRight/HeaderInfoRight';
import BackgroundLayoutFirst from '../../layouts/BackgroundLayoutFirst/BackgroundLayoutFirst';
import LogoIcon from '../../images/logo.png';
import bgHeaderImg from '../../images/bg-header-gray.png';
import BackgroundLayoutFirstImgBg from '../../images/background-layout-first.png';
import bottomDecorativeImg from '../../images/hospital-selection-img-bottom.png';
import avatar2 from '../../images/photo-1.png';
import SelectionScreen from '../../components/SelectionScreen/SelectionScreen';
import listIcon1 from '../../images/Soraksi_color.png';
import listIcon1Active from '../../images/Soraksi_white.png';
import listIcon2 from '../../images/Hadassa_color.png';
import listIcon2Active from '../../images/Hadassa_white.png';
import listIcon3 from '../../images/Shiba_color.png';
import listIcon3Active from '../../images/Shiba_white.png';
import useUserStore from '../../store/userStore';
import './HospitalSelection.scss';
import Loader from '../../components/Loader/Loader';
import {API_BASE} from "../../api/constants";

const HospitalSelection = () => {
  const user = useUserStore((state) => state.user);
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchHospitals = async () => {
      const maxRetries = 3;
      let attempts = 0;
      let success = false;

      while (attempts < maxRetries && !success) {
        try {
          attempts++;
          const response = await axios.get(API_BASE+'/api/getFullCampaignsTest', {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
            timeout: 10000,
          });
          console.log(response.data);
          const hospitals = response.data;
          setData({
            avatar1: user?.avatarUrl,
            avatar2: avatar2,
            middleContent: {
              onlyText: 'ברוכים הבאים, יובל שמואל וחני נחמיאס',
              onlyListItems: null,
            },
            title: `צוות ביקורים${(<br />)}  מודים לכם על ההתנדבות היום!`,
            subtitle:
              'כדי שנוכל להתחיל, נא בחרו את בית החולים בו אתם מתנדבים היום:',
            buttons: hospitals.map((hospital, index) => ({
              label: hospital.Name,
              id: hospital.Id,
              href: '#',
              icon:
                index === 0
                  ? listIcon1
                  : index === 1
                    ? listIcon2
                    : index === 2
                      ? listIcon3
                      : listIcon3,
              activeIcon:
                index === 0
                  ? listIcon1Active
                  : index === 1
                    ? listIcon2Active
                    : index === 2
                      ? listIcon3Active
                      : listIcon3Active,
            })),
          });
          success = true;
        } catch (error) {
          console.error(
            `Attempt ${attempts} failed to fetch hospitals:`,
            error
          );
          if (attempts >= maxRetries) {
            console.error('Max retries reached. Unable to fetch hospitals.');
          }
        }
      }
    };

    fetchHospitals();
  }, [user]);

  return (
    <BackgroundLayoutFirst
      backgroundImage={BackgroundLayoutFirstImgBg}
      backgroundColor="rgba(60, 75, 96, 0.8)"
      bottomDecorativeImage={bottomDecorativeImg}
      classNameUnic="hospital-style"
    >
      <Header
        rightBlock={
          data ? (
            <HeaderInfoRight
              avatar1={data.avatar1}
              middleContent={data.middleContent}
              icons={data.icons}
              buttonBackgroundColor="#7f8ea1"
            />
          ) : (
            <Loader />
          )
        }
        centerLogo={<img src={LogoIcon} alt="Logo" />}
        backgroundImage={bgHeaderImg}
        navBackgroundColor="rgba(60, 75, 96, 0.8)"
      />
      {data ? (
        <SelectionScreen
          title={<>צוות ביקורים{<br />} מודים לכם על ההתנדבות היום!</>}
          subtitle={data.subtitle}
          buttons={data.buttons}
          isHospitalSelection
        />
      ) : (
        <Loader />
      )}
    </BackgroundLayoutFirst>
  );
};

export default HospitalSelection;
