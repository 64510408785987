import React from 'react';
import './RadioButton.scss';

const RadioButton = ({ id, name, checked, onChange, label }) => {
  return (
    <li className="radio-button">
      <input
        type="checkbox"
        id={id}
        name={name}
        checked={checked}
        onChange={onChange}
      />
      <label htmlFor={id}>
        <span>{label}</span>
      </label>
    </li>
  );
};

export default RadioButton;
