import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from '../../components/Header/Header';
import HeaderInfoRight from '../../components/HeaderInfoRight/HeaderInfoRight';
import BackgroundLayoutFirst from '../../layouts/BackgroundLayoutFirst/BackgroundLayoutFirst';
import LogoIcon from '../../images/logo.png';
import bgHeaderImg from '../../images/bg-header-gray.png';
import BackgroundLayoutFirstImgBg from '../../images/background-layout-first.png';
import bottomDecorativeImg from '../../images/hospital-selection-img-bottom.png';
import avatar1 from '../../images/photo-2.png';
import avatar2 from '../../images/photo-1.png';
import SelectionScreen from '../../components/SelectionScreen/SelectionScreen';
import HeaderInfoRightIcon1 from '../../images/ico-hospital-litle.png';
import './ProjectSelection.scss';
import useStagesStore from '../../store/useStagesStore';
import { useAuth } from '../../hooks/AuthProvider';
import useUserStore from '../../store/userStore';
import Loader from '../../components/Loader/Loader';
import { useNavigate } from 'react-router-dom';
import {API_BASE} from "../../api/constants";

const ProjectSelection = () => {
  const user = useUserStore((state) => state.user);
  const [data, setData] = useState(null);
  const stages = useStagesStore((state) => state.stages);
  const setStage = useStagesStore((state) => state.setStage);
  const navigate = useNavigate();

  const { token } = useAuth();
  const today = new Date().toISOString().split('T')[0];

  useEffect(() => {
    const fetchProjects = async () => {
      const hospitalName = stages['select-hospital'].name;
      const volunteerType = stages['select-volunteer-type'].typeName;
      const typeSalesforce = stages['select-volunteer-type'].typeSalesforce;

      try {
        const response = await axios.get(API_BASE+'/api/project', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            organizationName: hospitalName,
            type: volunteerType,
            typeSalesforce,
            today,
          },
        });

        console.log(response);
        const projects = response.data;
        const projectButtons = projects.map((project) => ({
          label: project.Name,
          href: '#',
          id: project.Id,
          icon: project.ImageUrl,
        }));

        setData({
          avatar1: user?.avatarUrl,
          avatar2: avatar2,
          middleContent: {
            onlyText: null,
            onlyListItems: [
              {
                label: 'בית החולים ' + `"${stages['select-hospital'].name}"`,
                icon: HeaderInfoRightIcon1,
              },
            ],
          },
          title: 'בחרו את סוג הפעילות הרצוי',
          subtitle: null,
          volunteer: {
            label: stages['select-volunteer-type'].name,
            icon: stages['select-volunteer-type'].icon,
            href: '#',
          },
          projects: projectButtons,
        });
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    };

    fetchProjects();
  }, [token, user, stages, today]);

  const handleButtonClick = (button) => {
    setStage('selected-project', { id: button.id, name: button.label });
    setStage('currentStage', 'cart-selection');
    navigate('/cart-selection', { state: { selectedProject: button } });
  };

  return (
    <BackgroundLayoutFirst
      backgroundImage={BackgroundLayoutFirstImgBg}
      backgroundColor="rgba(60, 75, 96, 0.8)"
      bottomDecorativeImage={bottomDecorativeImg}
      classNameUnic="project-style"
    >
      <Header
        rightBlock={
          data ? (
            <HeaderInfoRight
              avatar1={data.avatar1}
              avatar2={data.avatar2}
              middleContent={data.middleContent}
              buttonBackgroundColor="#7f8ea1"
            />
          ) : (
            <Loader />
          )
        }
        centerLogo={<img src={LogoIcon} alt="Logo" />}
        backgroundImage={bgHeaderImg}
        navBackgroundColor="rgba(60, 75, 96, 0.8)"
      />
      {data ? (
        <div className="project-selection">
          <SelectionScreen
            title={data.title}
            subtitle={data.subtitle}
            buttons={[data.volunteer, ...data.projects]}
            isVolunteerSelection={true}
            isProjectSelection={true}
            onButtonClick={handleButtonClick}
          />
        </div>
      ) : (
        <Loader />
      )}
    </BackgroundLayoutFirst>
  );
};

export default ProjectSelection;
