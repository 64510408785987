import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/AuthProvider';
import Header from '../../components/Header/Header';
import HeaderInfoRight from '../../components/HeaderInfoRight/HeaderInfoRight';
import BackgroundLayoutFirst from '../../layouts/BackgroundLayoutFirst/BackgroundLayoutFirst';
import LogoIcon from '../../images/logo.png';
import bgHeaderImg from '../../images/bg-header-gray.png';
import BackgroundLayoutFirstImgBg from '../../images/background-layout-first.png';
import bottomDecorativeImg from '../../images/hospital-selection-img-bottom.png';
import avatar1 from '../../images/photo-2.png';
import avatar2 from '../../images/photo-1.png';
import SelectionScreen from '../../components/SelectionScreen/SelectionScreen';
import volunteerIcon1 from '../../images/volunteerIcon1.png';
import volunteerIcon2 from '../../images/volunteerIcon2.png';
import volunteerIcon3 from '../../images/volunteerIcon3.png';
import HeaderInfoRightIcon1 from '../../images/ico-hospital-litle.png';
import Loader from '../../components/Loader/Loader';
import './Instructions.scss';
import useUserStore from '../../store/userStore';
import useStagesStore from '../../store/useStagesStore';

const VolunteerTypeSelection = () => {
  const user = useUserStore((state) => state.user);
  const stages = useStagesStore((state) => state.stages);
  const setStage = useStagesStore((state) => state.setStage);

  const [data, setData] = useState(null);
  const navigate = useNavigate();
  const { token } = useAuth();

  useEffect(() => {
    const fetchVolunteerTypes = async () => {
      try {
        const response = await axios.get('/api/volunteer-types', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const volunteerTypes = response.data;

        setData({
          avatar1: user?.avatarUrl,
          avatar2: avatar2,
          middleContent: {
            onlyText: null,
            onlyListItems: [
              {
                label: 'בית החולים ' + `"${stages['select-hospital'].name}"`,
                icon: HeaderInfoRightIcon1,
              },
            ],
          },
          title: 'בחרו את סוג הפעילות הרצוי',
          subtitle: null,
          buttons: volunteerTypes.map((type, index) => ({
            label: type.Name,
            id: type.Id,
            typeName: type.typeNmae,
            typeSalesforce: type.typeSalesforce,
            icon: [volunteerIcon1, volunteerIcon2, volunteerIcon3][index % 3],
          })),
        });
      } catch (error) {
        console.error('Error fetching volunteer types:', error);
      }
    };

    fetchVolunteerTypes();
  }, [token]);

  const handleButtonClick = (button) => {
    setStage('select-volunteer-type', {
      id: button.id,
      name: button.label,
      typeName: button.typeName,
      typeSalesforce: button.typeSalesforce,
      icon: button.icon,
    });
    setStage('currentStage', 'project-selection');
    navigate('/project-selection');
  };

  return (
    <BackgroundLayoutFirst
      backgroundImage={BackgroundLayoutFirstImgBg}
      backgroundColor="rgba(60, 75, 96, 0.8)"
      bottomDecorativeImage={bottomDecorativeImg}
      classNameUnic="volunteer-style"
    >
      <Header
        rightBlock={
          data ? (
            <HeaderInfoRight
              avatar1={data.avatar1}
              avatar2={data.avatar2}
              middleContent={data.middleContent}
              buttonBackgroundColor="#7f8ea1"
            />
          ) : (
            <Loader />
          )
        }
        centerLogo={<img src={LogoIcon} alt="Logo" />}
        backgroundImage={bgHeaderImg}
        navBackgroundColor="rgba(60, 75, 96, 0.8)"
      />
      {data ? (
        <SelectionScreen
          title={data.title}
          subtitle={data.subtitle}
          buttons={data.buttons}
          onButtonClick={handleButtonClick}
        />
      ) : (
        <Loader />
      )}
    </BackgroundLayoutFirst>
  );
};

export default VolunteerTypeSelection;
