import React from 'react';
import './CartSelectionItem.scss';

const CartSelectionItem = ({
  index,
  title,
  subtitle,
  icon,
  isReserved,
  onButtonClick,
}) => {
  return (
    <div className={`cart-selection-item${isReserved ? ' reserved' : ''}`}>
      <h2 className="title">{title + ' ' + (index + 1)}</h2>
      <p className="subtitle">{subtitle}</p>
      <div className="icon">
        <img src={icon} alt={`${title} icon`} />
      </div>
      <button className="button" onClick={onButtonClick} disabled={isReserved}>
        {isReserved ? 'עגלת תפוסה' : 'בחר עגלה'}
      </button>
    </div>
  );
};

export default CartSelectionItem;
