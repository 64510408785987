import React from 'react';
import './Loader.scss';

const Loader = ({ loaderAbsolut = null }) => {
  return (
    <div
      className={
        loaderAbsolut ? 'loader-wrapper loader-abdolute' : 'loader-wrapper'
      }
    >
      <div className="loader"></div>
    </div>
  );
};

export default Loader;
