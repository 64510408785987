import React from 'react';
import './App.scss';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import AuthProvider from './hooks/AuthProvider';
import PrivateRoute from './router/PrivateRoute';
import Login from './components/user/Login';
import HospitalSelection from './pages/HospitalSelection/HospitalSelection';
import VolunteerTypeSelection from './pages/VolunteerTypeSelection/VolunteerTypeSelection';
import ProjectSelection from './pages/ProjectSelection/ProjectSelection';
import CartSelection from './pages/CartSelection/CartSelection';
import SeniorVolunteerSelection from './pages/SeniorVolunteerSelection/SeniorVolunteerSelection';
import NavigationManager from './components/NavigationManager/NavigationManager';
import StageEditingScreen from './pages/StageEditingScreen/StageEditingScreen';
import ServiceCalls from './pages/ServiceCalls/ServiceCalls';
import AssignedServiceCalls from './pages/AssignedServiceCalls/AssignedServiceCalls';
import TeamRooms from './pages/RoomsPages/TeamActivityRoomsList';
import TeamNoRooms from './pages/RoomsPages/TeamActivityNoRooms';
import IndividualRooms from './pages/RoomsPages/IndividualActivityRoomsList';
import IndividualNoRooms from './pages/RoomsPages/IndividualNoRooms';
//import SingleRoom from './pages/IndividualActivityRoomsList/IndividualActivityRoomsList';
import Challenges from './pages/Challenges/Challenges';
import Instructions from './pages/Instructions/Instructions';

const App = () => {
  return (
    <Router>
      <AuthProvider>
        {/*<NavigationManager />*/}
        <Routes>
          <Route path="/" element={<Navigate to="/login" />} />
          <Route path="/login" element={<Login />} />
          <Route element={<PrivateRoute />}>
            <Route path="/select-hospital" element={<HospitalSelection />} />
            <Route
              path="/select-volunteer-type"
              element={<VolunteerTypeSelection />}
            />
            <Route
              path="/select-senior-volunteer"
              element={<SeniorVolunteerSelection />}
            />
            <Route path="/project-selection" element={<ProjectSelection />} />
            <Route path="/cart-selection" element={<CartSelection />} />
            <Route path="/edit-stages" element={<StageEditingScreen />} />
            <Route path="/service-calls" element={<ServiceCalls />} />
            <Route path="/assigned-service-calls" element={<AssignedServiceCalls />} />
            <Route path="/team-rooms" element={<TeamRooms />} />
            <Route path="/team-no-rooms" element={<TeamNoRooms />} />
            <Route path="/individual-rooms" element={<IndividualRooms />} />
            <Route path="/individual-no-rooms" element={<IndividualNoRooms />} />
            {/*<Route path="/single-rooms" element={<SingleRoom />} />*/}
            <Route path="/challenges" element={<Challenges />} />
            <Route path="/instructions" element={<Instructions />} />
          </Route>
        </Routes>
      </AuthProvider>
    </Router>
  );
};

export default App;
