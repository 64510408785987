import React, {useState} from 'react';
import './FixedFooter.scss';
import {Link} from "react-router-dom";

import createServiceCallImg from '../../images/image-create-service-call.png';
import CreateServiceCallPopup from "../CreateServiceCallPopup/CreateServiceCallPopup";


const FixedFooter = ({
	                     additionalClass,
	                     footerCenter,
	                     sideButtons
                     }) => {
	const [showCreateServiceCallPopup, setShowCreateServiceCallPopup] = useState(false)
	return (
		<div>
			<div className={"footer " + (additionalClass ? additionalClass : '')}>
				<button className='create-service-call' onClick={() => setShowCreateServiceCallPopup(true)}>
					<strong>פתיחת קריאה חדשה</strong>
					<img src={createServiceCallImg} alt={"פתיחת קריאה חדשה"} width={'186'} height={'186'} />
					<span className={'counter'}>0/5</span>
				</button>
				<div className='footer-center'>
					{footerCenter}
				</div>
				{sideButtons ?
					<div className='side-buttons'>
						{sideButtons}
					</div>
					: ''}
			</div>
			{showCreateServiceCallPopup ? <div>
				<CreateServiceCallPopup
					isOpen={showCreateServiceCallPopup}
					onClose={() => setShowCreateServiceCallPopup(false)}
					bgHeader="#b83b9d"
				/>
			</div> : ''}
		</div>
);
};

export default FixedFooter;
