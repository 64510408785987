import React, { useState } from 'react';
import Modal from '../Modal/Modal';
import RadioButton from '../RadioButton/RadioButton';
import reloadIcon from '../../images/reload-icon.png';

import './VolunteerSelectionModal.scss';

const VolunteerSelectionModal = ({
  isOpen,
  onClose,
  participants,
  contentObj,
  bgHeader,
}) => {
  const [selectedParticipants, setSelectedParticipants] = useState([]);
  const maxSelection = 3;

  const handleSelectionChange = (participant) => {
    if (selectedParticipants.includes(participant)) {
      setSelectedParticipants(
        selectedParticipants.filter((p) => p !== participant)
      );
    } else if (selectedParticipants.length < maxSelection) {
      setSelectedParticipants([...selectedParticipants, participant]);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={
        contentObj.title + ' ' + contentObj.number + ' - ' + contentObj.subtitle
      }
      modalHeaderBg={bgHeader}
      modalHeaderColor="#fff"
    >
      <div className="volunteer-selection-content selectVolounteer">
        <h3 className="modal-subtitle">
          <b>בחר מתנדבים לצוות העגלה</b> (ניתן לדלג על הבחירה)
        </h3>
        <div className="icon-top">
          <img src={contentObj.icon} alt="icon" />
        </div>

        <button type="button" className="reloadList">
          <span className="icon">
            <img src={reloadIcon} alt="icon" />
          </span>
          <span className="text">רענן רשימה</span>
        </button>

        <ul className="topVolounteersList">
          {participants.map((participant, index) => (
            <RadioButton
              key={index}
              id={`volounteerName${index}`}
              name="topVolounteerName"
              checked={selectedParticipants.includes(participant)}
              onChange={() => handleSelectionChange(participant)}
              label={participant.name}
            />
          ))}
        </ul>

        <div className="bottom-volounteers-list">
          <button className="submit-button" onClick={event =>  window.location.href='/service-calls'}>
            שמור והתחל התנדבות
          </button>
          <span className="info-current-radio">
            <span className="all-count">{maxSelection}</span>
            <span>/</span>
            <span className="current-count">{selectedParticipants.length}</span>
          </span>
        </div>
      </div>
    </Modal>
  );
};

export default VolunteerSelectionModal;
