import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useStagesStore from '../../store/useStagesStore';
import useUserStore from '../../store/userStore';
import Header from '../../components/Header/Header';
import BackgroundLayoutFirst from '../../layouts/BackgroundLayoutFirst/BackgroundLayoutFirst';
import Loader from '../../components/Loader/Loader';
import LogoIcon from '../../images/logo.png';
import bgHeaderImg from '../../images/bg-header-gray.png';
import BackgroundLayoutFirstImgBg from '../../images/background-layout-first.png';
import avatar2 from '../../images/photo-1.png';
import HeaderInfoRightIcon1 from '../../images/ico-hospital-litle.png';
import StageEditingScreenLogin from '../../components/StageEditingScreen/StageEditingScreenLogin';
import './StageEditingScreen.scss';

const StageEditingScreen = () => {
  const navigate = useNavigate();
  const { stages } = useStagesStore();
  const user = useUserStore((state) => state.user);
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const middleContent = {
        onlyText: null,
        onlyListItems: [
          {
            label: 'Информация о пользователе',
            icon: HeaderInfoRightIcon1,
          },
        ],
      };

      setData({
        avatar1: user?.avatarUrl,
        avatar2: avatar2,
        middleContent: middleContent,
      });
    };

    fetchData();
  }, [user]);

  return (
    <BackgroundLayoutFirst
      backgroundImage={BackgroundLayoutFirstImgBg}
      backgroundColor="rgba(60, 75, 96, 0.8)"
      bottomDecorativeImage={null}
    >
      <Header
        rightBlock={null}
        centerLogo={<img src={LogoIcon} alt="Logo" />}
        backgroundImage={bgHeaderImg}
        navBackgroundColor="rgba(60, 75, 96, 0.8)"
      />
      <div className="stage-editing-screen">
        <h1 className="stage-editing-screen__title">עריכת פרטי התנדבות</h1>
        <div className="stage-editing-screen__content">
          <StageEditingScreenLogin />
        </div>
      </div>
    </BackgroundLayoutFirst>
  );
};

export default StageEditingScreen;
