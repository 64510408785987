import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useStagesStore from '../../store/useStagesStore';
import backButtonIcon from '../../images/back-arrow-icon.png';
import './SelectionScreen.scss';

const SelectionScreen = ({
  title,
  subtitle,
  buttons,
  onButtonClick,
  isHospitalSelection,
  isVolunteerSelection,
  isProjectSelection,
}) => {
  const navigate = useNavigate();
  const [activeButtonIndex, setActiveButtonIndex] = useState(null);
  const setStage = useStagesStore((state) => state.setStage);

  const handleButtonClick = (e, index, button) => {
    e.preventDefault();
    setActiveButtonIndex(index);
    if (onButtonClick) {
      onButtonClick(button);
    }
    if (isHospitalSelection) {
      setStage('select-hospital', { id: button.id, name: button.label });
      setStage('currentStage', 'select-senior-volunteer');
      navigate('/select-senior-volunteer');
    }
  };

  const handleBackClick = () => {
    if (isProjectSelection) {
      setStage('currentStage', 'select-volunteer-type');
      navigate('/volunteer-type-selection');
    } else {
      navigate(-1);
    }
  };

  return (
    <div className="selection-screen">
      <div className="selection-screen__top">
        <h1 className="selection-screen__title">{title}</h1>
        {subtitle && <h2 className="selection-screen__subtitle">{subtitle}</h2>}

        {isVolunteerSelection && (
          <div className="selection-screen__back-wrap">
            <a
              href="#"
              className="selection-screen__back-btn"
              onClick={(e) => {
                e.preventDefault();
                handleBackClick();
              }}
            >
              {backButtonIcon && (
                <span className="selection-screen__back-icon">
                  <img src={backButtonIcon} alt="icon" />
                </span>
              )}
              <span className="selection-screen__back-label">אחורה</span>
            </a>
          </div>
        )}
      </div>

      <div className="selection-screen__list-wrap">
        {isVolunteerSelection && (
          <ul className="selection-screen__list volunteer-list">
            <li className={`selection-screen__list-item volunteer-item`}>
              <a
                href={buttons[0].href}
                className="selection-screen__link"
                onClick={(e) => handleButtonClick(e, 0, buttons[0])}
              >
                {buttons[0].icon && (
                  <span className="selection-screen__icon">
                    <img src={buttons[0].icon} alt="icon" />
                  </span>
                )}
                <span className="selection-screen__label">
                  {buttons[0].label}
                </span>
              </a>
            </li>
          </ul>
        )}
        <ul className="selection-screen__list">
          {buttons.slice(isVolunteerSelection ? 1 : 0).map((button, index) => (
            <li
              key={index}
              className={`selection-screen__list-item ${activeButtonIndex === index ? 'active' : ''}`}
            >
              <a
                href={button.href}
                className="selection-screen__link"
                onClick={(e) =>
                  handleButtonClick(
                    e,
                    isVolunteerSelection ? index + 1 : index,
                    button
                  )
                }
              >
                {button.icon && (
                  <span className="selection-screen__icon">
                    <img
                      src={
                        isHospitalSelection && activeButtonIndex === index
                          ? button.activeIcon
                          : button.icon
                      }
                      alt="icon"
                    />
                  </span>
                )}
                <span
                  className={`selection-screen__label ${!button.icon ? 'full' : ''}`}
                >
                  {button.label}
                </span>
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SelectionScreen;
