import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../hooks/AuthProvider';
import useUserStore from '../../store/userStore';
import useStagesStore from '../../store/useStagesStore';
import {API_BASE} from "../../api/constants";

const Login = () => {
  const [input, setInput] = useState({
    phone: '',
    code: '',
  });
  const [step, setStep] = useState(1);
  const [error, setError] = useState('');
  const auth = useAuth();
  const navigate = useNavigate();
  const setUser = useUserStore((state) => state.setUser);
  const { setStage, currentStage } = useStagesStore();

  useEffect(() => {
    if (auth.token && currentStage === 'select-hospital') {
      navigate('/select-hospital');
    }
  }, [auth.token, currentStage, navigate]);

  const handleSubmitEvent = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(API_BASE+'/api/login', { phone: input.phone });
      if (response.data.message) {
        setStep(2);
        setStage('login', { phone: input.phone });
        setError('');
      }
    } catch (error) {
      setError(error.response?.data || 'Error occurred while sending the code');
    }
  };

  const handleSubmitEvent2 = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(API_BASE+'/api/verify', {
        phone: input.phone,
        code: input.code,
      });
      if (response.data.token) {
        auth.loginAction({
          user: response.data.user,
          token: response.data.token,
        });

        const baseURL = 'https://bikurimapp.herokuapp.com';

        const photoResponse = await axios.get(API_BASE+`/api/getPhotoTest`, {
          params: { id: response.data.user.Id },
          headers: {
            Authorization: `Bearer ${response.data.token}`,
          },
        });

        const avatarUrl = `${baseURL}${photoResponse.data.data.url}`;
        setUser({ ...response.data.user, avatarUrl });
        setStage('currentStage', 'select-hospital');
        setError('');
      }
    } catch (error) {
      setError(error.response?.data || 'Invalid phone number or code');
      auth.logOut();
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInput((data) => ({ ...data, [name]: value }));
  };

  return (
    <div className="loginForm-wrapper">
      <strong className="logo">
        <Link to={'/'}>
          <img
            src={'./images/logo-big.png'}
            alt={''}
            width={'254px'}
            height={'93px'}
          />
        </Link>
      </strong>
      {step === 1 ? (
        <form onSubmit={handleSubmitEvent}>
          <h1>התחברות למערכת</h1>
          <label>הזינו את מס׳ הטלפון שלכם</label>
          <input
            onInput={handleChange}
            type={'tel'}
            name={'phone'}
            value={input.phone}
          />
          <input type={'submit'} value={'שלח'} />
          {error && <p>{error}</p>}
        </form>
      ) : (
        <form onSubmit={handleSubmitEvent2}>
          <h1>התחברות למערכת</h1>
          <label>
            שלחנו קוד למספר: <span>{input.phone}</span>
            <br />
            הזינו אותו כאן כדי להתחבר:
          </label>
          <input
            onInput={handleChange}
            type={'text'}
            name={'code'}
            value={input.code}
            maxLength={'4'}
          />
          <input type={'submit'} value={'שלח'} />
          {error && <p>{error}</p>}
        </form>
      )}
    </div>
  );
};

export default Login;
